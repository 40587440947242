<template>
    <div class="scienTech">
        <div class="scienTech_img_f">
        <div class="scienTech_img">
            <img src="@/assets/image/scienceandtechnology1.png">
            <p>逻辑、创意、视觉、落地</p>
        </div>
        </div>
        <div class="business">
            <div class="header">公司业务</div>
            <ul class="business_ul">
                <li>
                    <img src="@/assets/image/icon.png">
                    <p>技术咨询</p>
                    <div class="show">
                        <div class="h">技术咨询</div>
                        <div class="text">在软件开发商将开发项目确定下来之后，需要与需求方进行讨论，确定需求方对于软件开发的需要实现目标及其具体需要的功能等等，并确定是否可达成。</div>
                    </div>
                </li>
                <li>
                    <img src="@/assets/image/icon(1).png">
                    <p>技术设计</p>
                    <div class="show">
                        <div class="h">技术设计</div>
                        <div class="text">软件设计要根据上一阶段对软件功能需求分析的结果，来设计软件系统的框架结构、功能模块和数据库等等。分为总体设计和详细设计两个部分</div>
                    </div>
                </li>
                <li>
                    <img src="@/assets/image/icon(2).png">
                    <p>技术开发</p>
                    <div class="show">
                        <div class="h">技术开发</div>
                        <div class="text">软件开发流程中每上一个阶段都是下一个阶段的实施进行的基础。编程也是根据对软件设计，将软件设计的各部分需求通计算机程序代码来实现运行，编程有统一、规范的程序编写规则，保证软件程序的易懂性、易维护性。</div>
                    </div>
                </li>
                <li>
                    <img src="@/assets/image/icon(3).png">
                    <p>解决维护</p>
                    <div class="show">
                        <div class="h">解决维护</div>
                        <div class="text">用户在接收开发商交付的软件开发结果，并进行实际操作、测试运行，实现满意结果之后，对开发出来的软件进行验收。定制开发的软件通常都需要提供售后服务，定期对软件进行维护，或者根据用户出现的新需求，进行应用软件程序的修改，使之不断满足客户实际需求。</div>
                    </div>
                </li>
            </ul>
        </div>
        <!-- PC端设计模块 -->
        <div class="design">
            <el-row class="design_t"  >
                <el-col :span="10" v-show="design_tShow">
                    <div class="design_t_l">
                        <img src="@/assets/image/industry2.png">
                        <p>品牌设计</p>
                    </div>
                </el-col>
                <el-col :span="14"  class="design_t_r" v-show="design_tShow">
                    <ul>
                        <li>
                            <img src="@/assets/image/industrylogo1.png">
                            <p>品牌官网<br/>设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo2.png">
                            <p>计算机类<br/>产品设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo3.png">
                            <p>消费类电子<br/>产品设计</p>
                        </li>
                    </ul>
                </el-col>
            </el-row>
            <div class="wColor"></div>
            <el-row class="design_b">
                <el-col :span="14" class="design_b_l" v-show="design_bShow">
                    <ul>
                        <li><img src="@/assets/image/industrylogo4.png"><p>LOGO设计</p></li>
                        <li><img src="@/assets/image/industrylogo5.png"><p>POSM终端系统设计</p></li>
                        <li><img src="@/assets/image/industrylogo6.png"><p>结构设计</p></li>
                    </ul>
                </el-col>
                <el-col :span="10" class="design_b_r" v-show="design_bShow">
                    <img src="@/assets/image/industry3.png">
                    <p>视觉设计</p>
                </el-col>
            </el-row>
        </div>
        <!-- 移动端设计模块 -->
        <div class="mobile_design">
            <div class="mobile_design_t">
                <div class="header">品牌设计</div>
                    <ul>
                        <li>
                            <img src="@/assets/image/industrylogo1.png">
                            <p>品牌官网<br/>设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo2.png">
                            <p>计算机类<br/>产品设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo3.png">
                            <p>消费类电子<br/>产品设计</p>
                        </li>
                    </ul>
            </div>
            <div class="mobile_design_b">
                <div class="header">视觉设计</div>
                    <ul>
                        <li>
                            <img src="@/assets/image/industrylogo4.png">
                            <p>LOGO设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo5.png">
                            <p>POSM终端<br />系统设计</p>
                        </li>
                        <li>
                            <img src="@/assets/image/industrylogo6.png">
                            <p>结构设计</p>
                        </li>
                    </ul>
            </div>
        </div>
        <div class="serve">
            <img src="@/assets/image/scienceandtechnology2.png">
            <p class="p" name="serve_p" v-show="serveShow">服务领域</p>
            <ul v-show="serveShow">
                <li>
                    <img src="@/assets/image/icon(4).png">
                    <p>PC网站建设</p>
                    <p class="showP">品牌官网、专注于创意用户体验</p>
                </li>
                <li>
                    <img src="@/assets/image/icon(5).png">
                    <p>互动营销</p>
                    <p class="showP">策划方案，激发目标客户的咨询</p>
                </li>
                <li>
                    <img src="@/assets/image/icon(6).png">
                    <p>响应式网站</p>
                    <p class="showP">PC 、平板、手机、微信端四网合一 </p>
                </li>
                <li>
                    <img src="@/assets/image/icon(7).png">
                    <p>电商平台</p>
                    <p class="showP">PC商城让您网站全面覆盖互联网</p>
                </li>
            </ul>
        </div>
        <!-- 移动端模块 -->
        <div class="mobile_business">
            <div class="header">公司业务</div>
            <ul>
                <li>
                   <img src="@/assets/image/icon.png"> 
                   <div class="mobile_business_r">
                       <p class="p1">技术咨询</p>
                       <p class="p2">在软件开发商将开发项目确定下来之后，需要与需求方进行讨论，确定需求方对于软件开发的需要实现目标及其具体需要的功能等等，并确定是否可达成。</p>
                   </div>
                </li>
                <li>
                    <img src="@/assets/image/icon(1).png"> 
                    <div class="mobile_business_r">
                        <p class="p1">技术设计</p>
                        <p class="p2">软件设计要根据上一阶段对软件功能需求分析的结果，来设计软件系统的框架结构、功能模块和数据库等等。分为总体设计和详细设计两个部分</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/image/icon(2).png"> 
                    <div class="mobile_business_r">
                        <p class="p1">技术开发</p>
                        <p class="p2">软件开发流程中每上一个阶段都是下一个阶段的实施进行的基础。编程也是根据对软件设计，将软件设计的各部分需求通计算机程序代码来实现运行，编程有统一、规范的程序编写规则，保证软件程序的易懂性、易维护性。</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/image/icon(3).png"> 
                    <div class="mobile_business_r">
                       <p class="p1">解决维护</p>
                       <p class="p2">用户在接收开发商交付的软件开发结果，并进行实际操作、测试运行，实现满意结果之后，对开发出来的软件进行验收。定制开发的软件通常都需要提供售后服务，定期对软件进行维护，或者根据用户出现的新需求，进行应用软件程序的修改，使之不断满足客户实际需求。</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="mobile_serve">
            <div class="mobile_serve_I">
                <img src="@/assets/image/scienceandtechnology2.png">
                <p class="p">服务领域</p>
            </div>
        <div class="recom">
            <ul>
                <li>
                    <img src="@/assets/image/icon(4).png">
                    <p class="p1">PC网站建设</p>
                    <p class="showP">品牌官网、专注于创意用户体验</p>
                </li>
                <li>
                    <img src="@/assets/image/icon(5).png">
                    <p class="p1">互动营销</p>
                    <p class="showP">策划方案，激发目标客户的咨询</p>
                </li>
                <li>
                    <img src="@/assets/image/icon(6).png">
                    <p class="p1">响应式网站</p>
                    <p class="showP">PC 、平板、手机、微信端四网合一 </p>
                </li>
                <li>
                    <img src="@/assets/image/icon(7).png">
                    <p class="p1">电商平台</p>
                    <p class="showP">PC商城让您网站全面覆盖互联网</p>
                </li>
            </ul>
        </div>
        </div>
        <!-- 移动端饼状图 -->
        <div class="mobilePie">
            <div class="header">方案流程</div>
            <p>六位一体，为快速开发而生</p>
            <img src="@/assets/image/mobilePie.png">
        </div>
        <!-- echart -->
        <div class="echart">
            <div class="echart_text" v-show="echartShow">
                <div class="echart_text_t">方案流程</div>
                <div class="echart_text_b">
                    <span class="span1"></span>
                    <span class="span2">六位一体，为快速开发而生</span>
                    <span class="span3"></span>
                </div>
            </div>
            <div class="echart_c" v-show="echartShow">
                <!-- 扇形图 -->
                <div class="sector" id="main"></div>
                <img src="@/assets/image/echartborder.png">
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    ToolboxComponent 
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';

echarts.use(
    [TitleComponent, TooltipComponent, PieChart,GridComponent, CanvasRenderer,LegendComponent,ToolboxComponent ]
);

export default {
    data(){
        return{
            businessShow:false,
            design_tShow:false,
            design_bShow:false,
            serveShow:false,
            echartShow:false,
            windowWidth:document.documentElement.clientWidth
        }
    },
    mounted() {
        this.myEchart()
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth
                this.windowWidth = window.fullWidth // 宽
            })()
        }
        if(this.windowWidth < 750){
            this.businessShow=true,
            this.design_tShow=true,
            this.design_bShow=true,
            this.serveShow=true,
            this.echartShow=true
        }
        window.addEventListener('scroll', this.handleScroll, true);  // 监听（绑定）滚轮滚动事件
    },
    methods: {
        myEchart() {
            var myChart = echarts.init(document.getElementById('main'));
            var option;

            option = {
                title: {
                    text:'敏捷的\n业务流程',
                    x: 'center',
                    y: 'center',
                    textStyle: {
                        // 设置字体样式
                        color: '#088bfa',
                    }
                },
                legend: {
                    top: 'bottom',
                    show:false
                },
                toolbox: {
                    show: false,
                    feature: {
                        mark: {show: true},
                        dataView: {show: true, readOnly: false},
                        restore: {show: true},
                        saveAsImage: {show: true},
                    }
                },
                tooltip: {
                    show: true,
                    trigger: 'item',
                    triggerOn:"mousemove",
                    showContent:true, 
                    // enterable:true,
                    confine:false, 
                    // position:['50%', '50%'],
                    transitionDuration:0, 
                    formatter: function (params) { 
                        // alert(JSON.stringify(params));
                        let text = ['界面交互设计、原型设计稿','上线后的维护工作','跟进上线、排查','单元测试、动能测试、测试报告','架构设计、界面布局、功能实现、数据对接','logo设计、视觉设计稿']
                        if(params.name== '原型策划') {
                            return `<p>${text[0]}</p>`
                        } else if(params.name== '更新维护') {
                            return `<p>${text[1]}</p>`
                        } else if (params.name== '上线') {
                            return `<p>${text[2]}</p>`
                        } else if (params.name== '测试') {
                            return `<p>${text[3]}</p>`
                        } else if (params.name== '程序开发') {
                            return `<p>${text[4]}</p>`
                        } else {
                            return `<p>${text[5]}</p>`
                        }
                    },
                },
                series: [
                    {
                        // name: '面积模式',
                        type: 'pie',
                        radius: [50, 250],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8,
                            backgroundColor: '#61a8fd',
                            borderWidth: 1,
                            borderColor: '#fff',
                        },
                        label: {
                                show: true,
                                position:'inner',   //显示在扇形上
                                fontSize: 26,
                                color: '#fff'
                        },
                        data: [
                            {value: 50, name: '更新维护',text:'上线后的维护工作1',itemStyle:{color:'#61a8fd'}},
                            {value: 50, name: '上线',text:'上线后的维护工作2',itemStyle:{color:'#61a8fd'}},
                            {value: 50, name: '测试',text:'上线后的维护工作3',itemStyle:{color:'#61a8fd'}},
                            {value: 50, name: '程序开发',text:'上线后的维护工作4',itemStyle:{color:'#61a8fd'}},
                            {value: 50, name: 'UI设计',text:'上线后的维护工作5',itemStyle:{color:'#61a8fd'}},
                            {value: 50, name: '原型策划',text:'上线后的维护工作6',itemStyle:{color:'#61a8fd'}}
                        ]
                    }
                ]
            };

            option && myChart.setOption(option);
        },
        // 滚动监听 · 动画播放
        handleScroll(){ 
            if(this.windowWidth < 750) return
                // 页面滚动距顶部距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || 
                    document.body.scrollTop
            //动画播放设置
            //品牌设计
            let design = document.getElementsByClassName('design')
            let design_t_l = design[0].getElementsByClassName('design_t_l')
            let design_t_r = design[0].getElementsByClassName('design_t_r')
            let design_b_l = design[0].getElementsByClassName('design_b_l')
            let design_b_r = design[0].getElementsByClassName('design_b_r')
            //服务领域
            let serve = document.getElementsByClassName('serve')
            let serve_p = document.getElementsByName('serve_p')
            let serveUl = serve[0].getElementsByTagName('ul')
            //方案流程
            let echart = document.getElementsByClassName('echart')
            let echart_text = echart[0].getElementsByClassName('echart_text')
            let echart_c = echart[0].getElementsByClassName('echart_c')
            if(scrollTop > (design[0].getBoundingClientRect().top + scrollTop) - design[0].offsetHeight){
                this.design_tShow = true
                design_t_l[0].classList.add('animated','fadeInLeft')
                design_t_r[0].classList.add('animated','fadeInRight')
            }
            if(scrollTop > (design[0].getBoundingClientRect().top + scrollTop) - design[0].offsetHeight / 2){
                this.design_bShow = true
                design_b_l[0].classList.add('animated','fadeInLeft')
                design_b_r[0].classList.add('animated','fadeInRight')
            }
            if(scrollTop > (serve[0].getBoundingClientRect().top + scrollTop) - serve[0].offsetHeight){
                this.serveShow = true
                serve_p[0].classList.add('animated','fadeIn')
                serveUl[0].classList.add('animated','fadeIn')
            }
            if(scrollTop > (echart[0].getBoundingClientRect().top + scrollTop) - echart[0].offsetHeight / 2 ){
                this.echartShow = true
                echart_text[0].classList.add('animated','fadeIn')
                echart_c[0].classList.add('animated','zoomIn')
            }
        } 
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    },
}
</script>

<style lang="less" scoped>
    .scienTech {
        width: 100%;
        .scienTech_img_f{
                height: 420px;
                overflow: hidden;
            .scienTech_img {
                position: relative;
                transition: all 0.6s;    //设置动画执行的时间为0.6s               
                &:hover {
                        transform: scale(1.2);     //设置图片按照比例放大1.2倍
                    }
                img {
                    width: 100%;
                    height: 420px;
                }
                p {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-size: 56px;
                    font-family: PangMenZhengDao;
                    color: #fff;
                    transform: translate(-50%,-50%);
                }
            }
        }
        .business {
            height: 1030px;
            position:relative;
            .header {
                font-size: 36px;
                font-family: SourceHanSansCN;
                font-weight: bold;
                color: #333333;
                margin: 90px 0 70px;
            }
            .business_ul {
                position:absolute;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                flex-wrap: wrap;
                width: 1230px;
                height: 800px;
                li {
                    width: 585px;
                    height: 370px;
                    margin: 15px;
                    padding-top: 111px;
                    transition: all 2s;
                    p {
                        font-size: 32px;
                        color: #FFFFFF;
                        margin-top:20px;
                    }
                    &:nth-child(1) {
                        background: url('~@/assets/image/background1.png') no-repeat;
                        img {
                            width: 86px;
                            height: 78px;
                        }
                        &:hover {
                            background: url('~@/assets/image/icon1.png');
                            img,p {
                                display: none;
                            }
                            .show {
                                display: block;
                            }
                        }
                    }
                    &:nth-child(2) {
                        background: url('~@/assets/image/background2.png') no-repeat;
                        img {
                            width: 86px;
                            height: 78px;
                        }
                        &:hover {
                            background: url('~@/assets/image/icon2.png');
                            img,p {
                                display: none;
                            }
                            .show {
                                display: block;
                            }
                        }
                    }
                    &:nth-child(3) {
                        background: url('~@/assets/image/background3.png') no-repeat;
                        img {
                            width: 86px;
                            height: 78px;
                        }
                        &:hover {
                            background: url('~@/assets/image/icon3.png');
                            img,p {
                                display: none;
                            }
                            .show {
                                display: block;
                            }
                        }
                    }
                    &:nth-child(4) {
                        background: url('~@/assets/image/background4.png') no-repeat;
                        img {
                            width: 86px;
                            height: 78px;
                        }
                        &:hover {
                            background: url('~@/assets/image/icon4.png');
                            img,p {
                                display: none;
                            }
                            .show {
                                display: block;
                            }
                        }
                    }
                    .show {
                        display: none;
                        padding: 0 60px;
                        .h {
                            font-size: 32px;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                        .text {
                            font-size: 16px;
                            color: #FFFFFF;
                            line-height: 30px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
        .design {
            margin-bottom: 60px;
            .design_t {
                display: flex;
                height: 504px;  
                background-color: #f8f8f8;

                .design_t_l {
                    height: 504px;
                    overflow: hidden;
                    position: relative;
                    img {
                        height: 504px;
                        width: 100%;
                        transition: all 0.6s;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                    p{
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 20%;
                        font-size: 44px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                }
                .design_t_r {
                    position: relative;
                    ul {
                        position: absolute;
                        top: 65%;
                        left: 35%;
                        transform: translate(-35%,-65%);
                        display: flex;
                        // justify-content: center;
                        li {
                            @keyframes icon{
                                0%{
                                    transform: translate(0,0);
                                }
                                50%{
                                    transform: translate(0px,-20px);
                                }
                                100%{
                                    transform: translate(0,0);
                                }
                            }
                            &:nth-child(2) {
                                margin: 0 120px;
                            }
                            img {
                                &:hover {
                                    animation:  icon 0.5s linear 1;
                                }
                            }
                            p {
                                font-size: 18px;
                                font-family: Source Han Sans CN;
                                color: #333333;
                                margin-top: 22px;
                            }
                        }
                    }
                }
            }
            .wColor {
                height: 100px;
                background-color: #fff;
            }
            .design_b {
                display: flex;
                height: 504px;
                // margin-top: 100px;
                background-color: #fff;
                    .design_b_l {
                        position: relative;
                        ul {
                            position: absolute;
                            top: 65%;
                            left: 65%;
                            transform: translate(-65%,-65%);
                            display: flex;
                            li {
                                // border: 1px dashed #999;
                                &:nth-child(2) {
                                margin: 0 120px;
                                }
                            }
                            img {
                                &:hover {
                                    animation:  icon 0.5s linear 1;
                                }
                            }
                            p {
                                font-size: 18px;
                                font-family: Source Han Sans CN;
                                color: #333333;
                                margin-top: 22px;
                            }
                        }
                    }
                    .design_b_r {
                        overflow: hidden;
                        position: relative;
                        img {
                            height: 504px;
                            width: 100%;
                            transition: all 0.6s;
                            &:hover {
                                transform: scale(1.2);
                            }
                        }
                        p{
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            left: 20%;
                            font-size: 44px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                        }
                    }
            }
        }
        .serve {
            position: relative;
            height: 440px;
            img {
                width: 100%;
            }
            .p {
                position: absolute;
                top: 25%;
                left: 50%;
                transform: translate(-50%,-50%);
                font-size: 36px;
                color: #fff;
                font-weight: 700;
            }
            ul {
                display: flex;
                width: 1230px;
                position: absolute;
                bottom: -120px;
                left: 50%;
                transform: translateX(-50%);
                li {
                    // width: 277px;
                    height: 337px;
                    flex:1;
                    margin: 0 15px;
                    padding-top: 112px;
                    background-color: #fff;
                    box-shadow: 0px 0px 12px 0px rgba(7, 138, 250, 0.2);
                img {
                    width: 60px;
                    height: 60px;
                }
                p {
                    font-size: 24px;
                    color: #333333;
                    margin-top: 10px;
                    color: #333333;
                }
                .showP {
                    display: none;
                    font-size: 16px;
                    line-height: 50px;
                    color: #1c94fa;
                }
                &:hover {
                    padding-top: 100px;
                    .showP {
                        display: block;
                        }
                    }
                }
            }
        }
        .echart {
            position: relative;
            height: 1280px;
            .echart_text {
                margin-top: 208px;
                margin-bottom: 79px;
                .echart_text_t {
                    font-size: 36px;
                    font-weight: 700;
                    color: #333;
                }
                .echart_text_b {
                    font-size: 18px;
                    color: #666;
                    margin-top: 29px;

                    .span1 , .span3 {
                        display: inline-block;
                        width: 140px;
                        height: 1px;
                        background-color: #E7E7E7;
                        vertical-align: middle;
                    }
                    .span2 {
                        margin: 0 20px;
                        // vertical-align: middle;
                    }
                }
            }
            .echart_c {
                position: absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%,-40%);
                width: 900px;
                height: 855px;
                .sector {
                    position: absolute;
                    top: 50%;
                    left: 57%;
                    transform: translate(-57%,-50%);
                    width: 538px;
                    height: 538px;
                    // background: url('~@/assets/image/echartborder.png') ;
                }
                img {
                    width: 900px;
                    height: 855px;
                }

            }
        }
        .fadeIn{
            // animation-duration: 5s;         //动画持续时间
            animation-delay: .5s !important;              //动画延迟时间
            // animation-iteration-count: 2 !important;
        }
        .zoomIn,.fadeInRight,.fadeInLeft,.fadeInUp{
            // animation-duration: 5s;         //动画持续时间
            animation-delay: 1.5s !important;              //动画延迟时间
            // animation-iteration-count: 2 !important;
        }
    }
    @media screen and (max-width: 1024px) {
        .scienTech {
            .scienTech_img_f {
                height: 110px;

                .scienTech_img{
                    img {
                        height: 110px;
                    }
                    p {
                        font-size: 16px;
                    }
                }
            }
            .business {
                display: none;
            }
            .design {
                display: none;
            }
            .serve {
                display: none;
            }
            .echart {
                display: none;
            }
            .mobile_business {
                padding: 0 15px 40px;
                .header {
                    font-size: 19px;
                    font-weight: 700;
                    margin: 30px 0 20px;
                }
                ul {
                    text-align: left;
                    li {
                        // display: flex;
                        // justify-content: space-between;
                        position: relative;
                        width: 100%;
                        height: 188px;
                        // background: url('~@/assets/image/mobile_icon1.png') no-repeat;
                        margin-bottom: 10px;
                        padding: 0 15px;
                        &:nth-child(1) {
                        background-image: linear-gradient(to right, #4a98ed , #0d5ab0);
                        }
                        &:nth-child(2) {
                        background-image: linear-gradient(to right, #4aa1c2 , #1d718f);
                        }
                        &:nth-child(3) {
                        background-image: linear-gradient(to right, #418bca , #0d4791);
                        }
                        &:nth-child(4) {
                        background-image: linear-gradient(to right, #5382e2 , #25479e);
                        }
                        img {
                            position: absolute;
                            top: 50%;
                            left: 15px;
                            transform: translateY(-50%);
                            width: 62px;
                            height: 62px;
                        }
                        .mobile_business_r {
                            // margin-left: 80px;
                            // padding: 18px 0;
                            position: absolute;
                            top: 50%;
                            left: 100px;
                            transform: translateY(-50%);
                            width: 230px;
                            .p1 {
                                font-size: 16px;
                                line-height: 26px;
                                color: #fff;
                            }
                            .p2 {
                                font-size: 9px;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .mobile_serve {
                .mobile_serve_I {
                    position: relative;
                    img {
                        width: 100%;
                       height: 115px; 
                    }
                    .p {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        font-size: 19px;
                        font-weight: 700;
                        color: #fff;
                    }
                }
                .recom {
                    position: relative;
                        height: 360px;
                    ul {
                        position: absolute;
                        top: 40%;
                        left: 53%;
                        transform: translate(-50%,-50%);
                        display: flex;
                        // justify-content: 
                        flex-wrap: wrap;
                        width: 90%;
                        // background-color: #f0f8ff;
                        li {
                            width: 44%;
                            margin: 8px;
                            padding: 0 15px;
                            background-color: #fff;
                            box-shadow: 10px 10px 5px #f0f8ff;
                            img {
                                width: 31px;
                                height: 32px;
                                margin: 20px 0 15px;
                            }
                            .p1 {
                                font-size: 12px;
                                font-weight: 700;
                                margin-bottom: 14px;
                            }
                            .showP {
                                font-size: 9px;
                                margin-bottom: 23px;
                            }
                        }
                    }
                }
            }
            .mobilePie {
                .header {
                    font-size: 19px;
                    margin-bottom: 9px;
                }
                p {
                    font-size: 12px;
                    color: #666;
                    margin-bottom: 19px;
                }
                img {
                    margin-bottom: 25px;
                    width: 300px;
                    height: 286px;
                }
            }
            .mobile_design {
                padding-top: 30px;
                background-color: #f8f8f8;
                .header {
                    font-size: 19px;
                    font-weight: 700;
                }
                ul {
                    display: flex;
                    justify-content: space-evenly;
                    li {
                        margin: 23px 0;
                        img {
                            width: 48px;
                            height: 48px;
                            // border: 1px dashed #666;
                        }
                        p {
                            font-size: 13px;
                        }
                    }
                }
                .mobile_design_b {
                    padding-top: 30px;
                    background-color: #fff;
                }
            }
        }
    }
    @media screen and (min-width: 1024px) {
        .mobile_business_r {
            width: 620px !important;
        }
        .mobile_serve {
            .recom {
                ul {
                    left: 53.5% !important;
                }
            }
        }
    }
    @media screen and (min-width: 1025px) {
        .scienTech {
            margin-top: 90px;
        }
        .mobile_business {
            display: none;
        }
        .mobile_serve {
            display: none;
        }
        .mobilePie {
            display: none;
        }
        .mobile_design {
            display: none;
        }
    }
    @media screen and (max-width: 374px) {
        .mobile_business {
            .p2 {
                width: 175px;
            }
        }
    }
    @media screen and (width: 768px) {
        .mobile_business {
            ul {
                li {
                    img {
                        left: 200px !important;
                    }
                }
            }
            .mobile_business_r {
                left: 300px !important;
            }
        }
    }

    @media screen and (width: 1024px) {
        .mobile_business {
            ul {
                li {
                    img {
                        left: 150px !important;
                    }
                }
            }
            .mobile_business_r {
                left: 250px !important;
            }
        }
    }
</style>